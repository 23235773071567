<template>
  <div>
    <el-menu
      class="el-menu-demo"
      :default-active="this.$route.path"
      mode="horizontal"
      text-color="#909399"
      router
      @select="handleSelect"
      @open="handleOpen"
    >
      <div class="logo">
        <a href="https://www.smartcert.cn/"><img :src="imgStr" /></a>
      </div>
      <el-menu-item index="/postpone/orderForm">订单查询</el-menu-item>
      <el-menu-item index="/postpone/onLine">在线延期</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import image from "../../assets/logo.png";
export default {
  data() {
    return {
      imgStr: image,
      activeKey:'/postpone/onLine'
    };
  },
  computed: {},
  watch: {
  },

  methods: {
    handleOpen(key, keyPath) {
  
    },
    handleSelect(key, keyPath) {
   
    },
  },
  created() {},
  mounted() {},
  components: {},
};
</script>
<style scoped>
.el-menu-demo {
  background: linear-gradient(-45deg, #eaf2fb, #f6fafe);
  text-align: center;
}

.el-menu-demo .logo {
  float: left;
  margin: 8px 0 0 20px;
}
.el-menu-demo li {
  float: right;
  margin: 0 15px 0 0;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
}
:deep( .el-menu--horizontal > .el-menu-item.is-active) {
 
  border-bottom: transparent;
}
</style>