<template>
  <el-container >
    <div class="container">
      <div>
      <el-header class="el-header">
      <HeaderBar />
     </el-header>
    </div>
    <div class="main-box">
        <router-view></router-view>
    </div>
    <div>
      <Footer />
    </div>
    </div>
  </el-container>
</template>

<script>
import HeaderBar from "../headerBar";
import Footer from '../footer'
export default {
  components: {
    HeaderBar,
    Footer,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  padding-right: 0px !important;
}
:deep(.el-menu.el-menu--horizontal) {
    border-bottom: solid 0px #e6e6e6;
}
.container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between
}
.el-header {
  width: 100%;
  line-height: 1;
  text-align: center;
}

.main-box {
  width: 100%;
  background-color: white;
  flex: 1;
  height: 100%;
}

</style>