<template>
    <el-footer style="height: 200px">
      <div class="footer_main">
  
        <div class="footer_container">
          <div class="leftLogo">
            <a href="https://www.smartcert.cn/">
              <img
                style="float: right; margin: 15px 50px 0 0"
                src="../../assets/footerLogo.png"
                alt=""
            /></a>
          </div>
            <div class="centerBox">
              <div  style="text-align: left">
                <a href="https://www.smartcert.cn/" style="text-decoration: none;">
                  
            <p style="margin-left: 22px;color:#fff;" >
              <img
                src="../../assets/icon_http.png"
                style="margin: 1px 8px 0px 0; width: 15px; height: 16px"
              />
              江苏智慧数字认证有限公司
            </p>
                </a>

            <p style="margin: 8px 0 8px 22px">
              <img
                src="../../assets/icon_add.png"
                style="margin: 10px 8px 0px 0; width: 15px; height: 16px"
              />
              <span> 南京市雨花台区软件大170-1号</span>
            </p>
            <p style="text-align: left; margin-left: 50px;margin-top: 12px;">天溯科技园2号楼6楼</p>
          </div>
 
          <div  style="text-align: left">
            <p style="margin-left: 22px">
              <img src="../../assets/icon_tel.png" 
              style="margin: 1px 8px 0px 0; width: 15px; height: 16px"/>
              400-823-8788 
            </p>
            <p style="margin: 18px 8px 8px 22px">
              <img  src="../../assets/icon_mail.png" 
              style="margin: 1px 8px 0px 0; width: 15px; height: 13px" />
              CA@SMARTCERT.CN
            </p>
        
          </div>
            </div>
            <div class="rightLogo">
              <img src="../../assets/weixinLogo.png" alt="" class="img" />
              <p class="weixin">微信扫一扫,关注公众号</p>
            </div>
        </div>
        <div class="footer_main-bottom">
          Copyright © 江苏智慧数字认证有限公司备案号: 苏ICP备18003020号-1
        </div>
      </div>
    </el-footer>
</template>

<script>

export default {

  data() {
    return {};
  },

  methods: {},
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.el-footer {
  width: 100%;
  background-color: blue;
  background-image: url(../../assets/footerBackgroud.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-origin: border-box;
  overflow: hidden;
}

.footer_main {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #1b2738;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer_container {
  width: 62%;
  min-width: 1000px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  box-sizing: border-box;
  margin-left: -31px;
  position: relative;
}
.footer_container .centerBox {
  text-align: center;
  height: 120px;
  width: 550px;
  display: flex;
  justify-content: space-around;

}
.leftLogo{
position: absolute;
left: 0px;
top: 42px;
}
.rightLogo{
  position: absolute;
  right: 0px;
  top: 27px;
}
.img{
  width: 130px;
}
.weixin{
  margin-top: 6px;
  margin-left: 5px;
  font-size: 9px;
  color: #EFF5FB;
}
.logo img {
  width: 116px;
  height: 116px;
  margin-top: -5px;
}
.logo span {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #eff5fb;
}
.footer_main-bottom {
  flex: 1 0 0;
  text-align: center;
  letter-spacing: 1px;
  margin-left: -2px;
  color: #9c9ea0;
  padding-bottom: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #9c9ea0;
}
/* .main-box {
  width: 100%;
  text-align: center;
  min-height: 72vh;
}
@media screen and (max-height: 750px) {
  .main-box {
    width: 100%;
    text-align: center;
    min-height:63.5vh;
  }
} */
</style>